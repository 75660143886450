
<div class="container haveData">
  <h2 style="margin: 20px 0px;">{{util.translate('My Orders')}}</h2>
  <div class="card_div" *ngFor="let item of myOrders" > <!-- (click)="goToHistoryDetail(item.orderId)" -->

    <div class="resto_detail">
      <div class="back_image"
        [ngStyle]="{'background-image':'url('+api.mediaURL+item.str_cover+'),url(assets/placeholder.jpg)'}"></div>
      <div style="margin-left: 20px;display: flex;flex-direction: column;">
        <label class="res_name" style="margin: 0px;">{{item.str_name}}</label>
        <label class="res_location" style="margin: 0px;">
          {{item.str_address}}
        </label>
      </div>
    </div>

    <div class="line_div"></div>
    <div class="order_detail">
      <label class="head_gray">{{util.translate('Lab Test')}}</label>
      <div class="small_lbl">
        <span *ngFor="let order of item.orders;let ol = index">
          <div *ngIf="!order.selectedItem || !order.selectedItem.length" class="border_bottom">
            <p>Test Id :{{order.test_id}}<br>
            {{order.name}} <!-- X {{order.quantiy}}  <span *ngIf="ol !=item.orders.length">,</span>-->
            <br>Rate : {{order.test_rate}}<br> 
            Parameters count : {{order.parameters_count}}</p>
          </div>
          <div *ngFor="let subItems of order.selectedItem;let j = index" class="subNames">
            <label class="food_title">{{order.name}} X {{order.selectedItem[j].total}}</label>
            <div *ngFor="let addods of subItems.item" class="flex_titles">
              <p class="sub_name">
                - {{addods.name}}
              </p>
              <p class="sub_name" *ngIf="util.cside ==='left'">
                {{util.currecny}} {{addods.value}}
              </p>
              <p class="sub_name" *ngIf="util.cside ==='right'">
                {{addods.value}} {{util.currecny}}
              </p>
            </div>
          </div>
        </span>
      </div>
      <label class="head_gray">{{util.translate('Ordered On')}}</label>
      <label class="small_lbl">{{getDate(item.time)}} </label>
      <label class="head_gray">{{util.translate('Total Amount')}}</label>
      <label class="small_lbl" *ngIf="util.cside ==='left'"> {{util.currecny}} {{item.total}}</label>
      <label class="small_lbl" *ngIf="util.cside ==='right'"> {{item.total}} {{util.currecny}}</label>
      <label class="head_gray">{{util.translate('Discount Amount')}}</label>
      <label class="small_lbl" *ngIf="util.cside ==='left'"> {{util.currecny}} {{item.discount}}</label>
      <label class="small_lbl" *ngIf="util.cside ==='right'"> {{item.discount}} {{util.currecny}}</label>
      <label class="head_gray">{{util.translate('Net Payable Amount')}}</label>
      <label class="small_lbl" *ngIf="util.cside ==='left'"> {{util.currecny}} {{item.total-item.discount}}</label>
      <label class="small_lbl" *ngIf="util.cside ==='right'"> {{item.item.total-item.discount}} {{util.currecny}}</label>
    </div>

    <div class="line_div"></div>

    <div class="status_detail" *ngIf="item.status =='completed' || item.status == 'delivered'">
      <div class="deliver_div">
        {{util.translate('Delivered')}}
      </div>
    </div>
    <div class="status_detail" *ngIf="item.status =='rejected' || item.status == 'cancel'">
      <div class="deliver_div">
        {{util.translate('Your Order is')}} {{item.status}}
      </div>
    </div>
    <div class="status_detail" *ngIf="item.status =='created' || item.status =='ongoing' || item.status === 'accepted'">
      <div class="deliver_div">
        {{util.translate('Your Order is')}} {{item.status}}
      </div>

      <div class="repeat_div" style="color: green;">
        <i class="fa fa-location-arrow" color="primary"></i> {{util.translate('Appointment Schedule:')}} : {{item.sdate}} {{item.stime}}
      </div>
      
      <!-- <div class="repeat_div" style="color: green;">
        <i class="fa fa-location-arrow" color="primary"></i> {{util.translate('Track Order')}}
      </div> -->
    </div>
  </div>


  <div *ngFor="let item of dummy" class="card_div">
    <div class="resto_detail">
      <div class="back_image">
        <ngx-skeleton-loader appearance="circle" [theme]="{ 'border-radius': '5px', height: '50px',width:'100%'}">
        </ngx-skeleton-loader>
      </div>
      <div style="margin-left: 20px;display: flex;flex-direction: column;">
        <label class="res_name" style="margin: 0px;">
          <ngx-skeleton-loader appearance="line" [theme]="{  height: '20px',width:'120px'}">
          </ngx-skeleton-loader>
        </label>
        <label class="res_location" style="margin: 0px;">
          <ngx-skeleton-loader appearance="line" [theme]="{  height: '20px',width:'70px'}"> </ngx-skeleton-loader>
        </label>
      </div>
    </div>
  </div>
</div>
